<template>
  <span>
      <span>    
        <input type="text" name="address" ref="address" v-model="address" @keyup="getAddressFunc($event)"/>
        </span>
  
    <ul v-show="seen">
         <li v-for="(item,index) in addressObj" :key="index"><a href="#" class="autocomplete" @click.prevent="addressSelectedFunc(item.description)">{{ item.description}}</a></li>
    </ul>
   </span>
 
</template>

<script>
export default {


  name: 'Autocomplete',
  props: {
    msg: String
  },
 data: function() {
        return {
          seen:false,
          address: '',
          addressObj:[],
          addressSelected:'',
          error:'',
          refs:''
        
        };
   },
  methods:{

          getAddressFunc: function(){
                let self=this;
                
              //alert("In getAddressFunc" + e.target.name.value); 
              
              if(self.address.length>1){
                  //alert(this.$refs.address.value);
                  this.seen=true;
                  self.callApiFunc(this.$refs.address.value);
              }
                
          },
          callApiFunc: function(address1){
              this.refs=address1;
              //alert("In callApiFunc"+this.address);
            
             
           // var url="http://110.173.133.144:8081/autocomplete/getAddress";
          // var url="https://medifoot.krishcom.com/autocomplete/getAddress";
          var url=process.env.VUE_APP_ROOT_API+"/autocomplete/getAddress";
            this.$http.get(url, {
                                     params: {
                                                queryString:this.refs
                                     }
                                 })
            .then(
                (response) => {
                        this.seen=true
                          this.addressObj = response.data.predictions});
                          
          },
          addressSelectedFunc:function(item){

              //alert("Address Selected" +item);
              this.addressSelected=item;
              this.seen=false;
              this.address=item;
              this.$emit('getAddress', this.addressSelected);
          }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.autocomplete{font-size:.75em;color:gray;}
ul{list-style-type:none;}
</style>
