<template>
  <div align="left">
    <b-container >
    <b-row>
      <b-col align="left">
       <span class="green" >{{responseMsg}} Shipping:${{shippingTotal}}  Total:${{totalFunc}}</span> 
      </b-col>
    </b-row>
    <b-row >
     <b-col  align="left">
    <form>
       <label for="moonBootType">Moon Boot Type:</label>
       <select v-model="moonBootType" ref="moonBootType">
                <option disabled value="">Select one</option>
                <option>High Top</option>
                <option>Low Top</option>
       </select><span id="moonBootTypeError" class="errorClass"></span><br>

       <label for="qty" ref="qty">Qty:</label>
        <input type="text"  v-model="qty" readonly  size="1"><span id="qtyError" class="errorClass"></span><br>

        <label for="size">Size:</label>
         <select v-model="size" ref="size" id="size">
                <option disabled value="">Select one</option>
                <option>x-Sm (Men 2-4 | Women 3.5-3.5)</option>
                <option>Small (Men 4.5-7| Women 5.5-8.5)</option>
                <option>Med (Men 7.5-10.5 | Women 8.5-12)</option>
                <option>Large (Men 10.5-12.5 | Women 12-14)</option>
                <option>x-Lg (Men 12.5-15 | Women 14-16.5)</option>
       </select><span id="sizeError" class="errorClass"></span><br>

        <label for="price">Price:$</label>
        <input type="text"  v-model="price" Readonly size="3" ref="price"><span id="priceError" class="errorClass"></span><br>

      
        <input type="hidden"  v-model="deliveryAddress" ref="deliveryAddress">

        <label for="deliveryAddress">Deliver To:</label>
        <Autocomplete  @getAddress="updateparent" ></Autocomplete>
        <span id="deliveryAddressError" class="errorClass"></span>
        <br>
       <p v-show="distance<11000&&distance>0" class="green">3 Hour Shiping available</p>
        <label for="shippingType">Shipping Options:</label>
         
        <select v-model="shippingType" ref="shippingType" @change="calculateShipping($event)">
                <option disabled value="">Select Available</option>
                <option value="15">Next Day($15)</option>
                <option value="25" :disabled="distance>deliverDistance">3 Hour($25)</option>
       </select><span id="shippingTypeError" class="errorClass"></span><br>

        <label for="clientName">Addressed To:</label>
        <input type="text"  v-model="clientName" ref="clientName"><span id="clientNameError" class="errorClass"></span><br>

         <label for="contactNumber" >Mobile Num:</label>
        <input type="number" pattern="[0-9]*" inputmode="numeric" v-model="contactNumber" ref="contactNumber"><span id="contactNumberError" class="errorClass"></span><br>

        <label for="emailId">Email:</label>
        <input type="text"  v-model="emailId" ref="emailId"><span id="emailIdError" class="errorClass"></span><br>
       
       <!-- <input type="submit" value="Place Order" @click="submitFunc"/>-->
        <b-button variant="primary" @click="submitFunc" :disabled="isPlaceOrderBtnDisabled">Place Order</b-button>
    </form>
     </b-col>
    </b-row>
   </b-container>
  {{responseMsg}}
  <Getdistance :testProp="deliveryAddress" prop2="dd" @updateDistance="updateDistance"></Getdistance>
 
 
        
          <form method="get" :action=paymentSuccessUrl>
          <input type="hidden"  name="moonBootType"  v-model="moonBootType"/>
          <input type="hidden"  name="size"  v-model="size"/>
          <input type="hidden"  name="qty"  v-model="qty"/>
          <input type="hidden"  name="price"  v-model="price"/>
          <input type="hidden"  name="deliveryAddress"  v-model="deliveryAddress"/>
          <input type="hidden"  name="shippingType"  v-model="shippingType"/>
          <input type="hidden"  name="clientName"  v-model="clientName"/>
          <input type="hidden"  name="contactNumber"  v-model="contactNumber"/>
          <input type="hidden"  name="emailId"  v-model="emailId"/>
          <input type="hidden"  name="total" v-model="totalFunc"/>
          <input type="hidden"  name="shippingMethod" v-model="shippingMethodFunc"/>

              <div id="paymentButton">
              </div>
          </form>
 
 
</div>  
</template>

<script>

import Autocomplete from './Autocomplete.vue'
import Getdistance from './GetDistance.vue'

export default {
  name: 'MoonBoots',
  components: {
  Autocomplete,Getdistance
  },
  props: {
    msg: String
  },
     mounted: function()   {
      //  alert("Mounted");
    /**
    let plugin = document.createElement("script");
   // plugin.setAttribute("type","application/javascript");
    plugin.setAttribute("src","https://secure.ewaypayments.com/scripts/eCrypt.js");
    plugin.setAttribute("class","eway-paynow-button");
    plugin.setAttribute("data-publicapikey","epk-3A7EB7ED-37EA-4076-BF42-2E7E7CDA3D1A");
    plugin.setAttribute("v-bind:data-amount","shippingTotal");
    plugin.setAttribute("data-label","Make Payment");
    plugin.setAttribute("data-currency","AUD");
    plugin.setAttribute("data-invoicedescription","Foot&Ankle Products");
    plugin.setAttribute("data-buttoncolor","#428bca");
    plugin.setAttribute("data-buttonerrorcolor","#d9534f");
    plugin.setAttribute("data-buttonprocessedcolor","#5cb85c");
    plugin.setAttribute("data-submitform","yes");

    plugin.async = true;
    document.getElementById("paymentButton").appendChild(plugin);
    */
         },
    

 data: function() {
        return {
          orderObj:{},
          moonBootType:'',
          size:'',
          qty:1,
          price:99,
          deliveryAddress:'',
          shippingType:'',
          clientName:'',
          contactNumber:'',
          emailId: '',
          responseMsg:'',
          errorObj:{},
          distance:0,
          shippingTotal:0,
          is3HourShippingAvailable:false,
         isPlaceOrderBtnDisabled:false,
          grandTotal:0,
          paymentSuccessUrl:process.env.VUE_APP_ROOT_API + '/payment-success',
          deliverDistance:process.env.VUE_APP_DELIVERY_DIST
        };
   },
   
   computed:{

       totalFunc:function(){
        
           return this.shippingTotal+this.price;
       },
       shippingMethodFunc:function(){
          
         return (this.shippingType==25)?'3 Hour':'Next Day';
       }
   },
 
  methods:{
     
         clearErrorFunc: function(){
                var x = document.getElementsByClassName("errorClass");
                var ierror;
                    for (ierror = 0; ierror < x.length; ierror++) {
                        x[ierror].innerHTML= "";
                   }    
          },
          createPaymentButton:function(){

    document.getElementById("paymentButton").innerHTML="";  
   let plugin = document.createElement("script");
    // plugin.setAttribute("type","application/javascript");
    plugin.setAttribute("src","https://secure.ewaypayments.com/scripts/eCrypt.js");
    plugin.setAttribute("class","eway-paynow-button");
    plugin.setAttribute("data-publicapikey","epk-3A7EB7ED-37EA-4076-BF42-2E7E7CDA3D1A");
    plugin.setAttribute("data-amount",this.totalFunc*100);
    plugin.setAttribute("data-email",this.emailId);
    plugin.setAttribute("data-phone",this.contactNumber);
    plugin.setAttribute("data-label","Make Payment");
    plugin.setAttribute("data-currency","AUD");
    plugin.setAttribute("data-invoicedescription","Foot&Ankle Products");
    plugin.setAttribute("data-buttoncolor","#428bca");
    plugin.setAttribute("data-buttonerrorcolor","#d9534f");
    plugin.setAttribute("data-buttonprocessedcolor","#5cb85c");
    // plugin.setAttribute("data-resulturl","http://localhost:8081/payment-success");
   
    plugin.setAttribute("data-submitform","yes");

    plugin.async = true;
    document.getElementById("paymentButton").appendChild(plugin);

    document.getElementById("paymentButton").addEventListener("DOMNodeInserted", function (ev) {
        if(ev.target.tagName=="A"){
        //alert("Payment accepted");
        }
    }, false);      

         },
        

          submitFunc: function(e){
              e.preventDefault();
              this.clearErrorFunc();
              var self=this;
              self.orderObj.moonBootType=self.moonBootType;
              self.orderObj.size=self.size;
              self.orderObj.qty=self.qty;
              self.orderObj.price=self.price;
              self.orderObj.deliveryAddress=self.deliveryAddress;
              self.orderObj.shippingType=self.shippingType;
              self.orderObj.clientName=self.clientName;
              self.orderObj.contactNumber=self.contactNumber;
              self.orderObj.emailId=self.emailId;

             
             //alert("In  order submit Function" + JSON.stringify(self.orderObj));
           //this.$http.post("http://110.173.133.144:8081/getOrder",self.orderObj)
           //this.$http.post("https://medifoot.krishcom.com/getOrder",self.orderObj)
           this.$http.post(process.env.VUE_APP_ROOT_API+"/getOrder",self.orderObj)
            .then(response => 
            {                
                alert(response.data +" After payment please wait to get to the next page");
                this.isPlaceOrderBtnDisabled=true;
                this.responseMsg = response.data;
                this.createPaymentButton();
                //this.showPaymemtButton=true;
               
                
             })
            .catch(error => {
             this.errorObj =error.response.data;
             //alert("HK"+this.errorObj.size);
             for(var i in this.errorObj){
                 console.log(i + this.errorObj[i]);
                 document.getElementById(i+'Error').innerHTML=this.errorObj[i]; 
                     
             }
              var x = document.getElementsByClassName("errorClass");
                var ierror;
                    for (ierror = 0; ierror < x.length; ierror++) {
                        x[ierror].style.color = "red";
                        x[ierror].style.fontSize = "0.75em";
                   }      
             
    });
            /**
            this.$http.get("http://localhost:8081/sendEmail")
           
            .then(response => 
            {                
                this.responseMsg = response.data;
             //  alert(response.data);
               
                
             })    
             */
          },
          updateparent:function(a){
             //alert("Address Selected:"+a);
              this.deliveryAddress=a;
              //this.distance++;
          },

         updateDistance:function(a){
            // alert("Distance:"+a);
              this.distance=a;
         },
         calculateShipping:function(event){

             this.shippingTotal=parseInt(event.target.value);
             //let list = document.getElementById("paymentButton"); 
            // alert(list.childNodes.length);
                          
                document.getElementById("paymentButton").innerHTML="";
               

          /**    
    let plugin = document.createElement("script");
  // plugin.setAttribute("type","application/javascript");
    plugin.setAttribute("src","https://secure.ewaypayments.com/scripts/eCrypt.js");
    plugin.setAttribute("class","eway-paynow-button");
    plugin.setAttribute("data-publicapikey","epk-3A7EB7ED-37EA-4076-BF42-2E7E7CDA3D1A");
    plugin.setAttribute("data-amount",this.totalFunc*100);
    plugin.setAttribute("data-label","Make Payment");
    plugin.setAttribute("data-currency","AUD");
    plugin.setAttribute("data-invoicedescription","Foot&Ankle Products");
    plugin.setAttribute("data-email",this.emailId);
    plugin.setAttribute("data-phone",this.contactNumber);

    plugin.setAttribute("data-buttoncolor","#428bca");
    plugin.setAttribute("data-buttonerrorcolor","#d9534f");
    plugin.setAttribute("data-buttonprocessedcolor","#5cb85c");
  // plugin.setAttribute("data-resulturl","http://localhost:8081/payment-success");
   
   plugin.setAttribute("data-submitform","yes");

    plugin.async = true;
    document.getElementById("paymentButton").appendChild(plugin);

    document.getElementById("paymentButton").addEventListener("DOMNodeInserted", function (ev) {
        if(ev.target.tagName=="A"){
        //alert("Payment accepted");
        }
}, false); 
  */     
         },
          
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.green{color:green;}

</style>
