<template>
  <div align="left">
  <b-container >
    <b-row>
     <b-col> <b-link href="/">  <h2>{{msg}}</h2>    </b-link> </b-col>
    </b-row>
    <b-row >
        <b-col align="left">
           <span class="heading4"> <b-link href="https://www.medifootclinic.com.au">medifoot clinic</b-link></span>
          &nbsp; <b-link class="phone" href="tel:+61393057945">ph: 9305 7945</b-link>
        </b-col>
     </b-row>
     <b-row >
          <b-col> <b-img src="@/assets/Lowtop.jpg"  float alt="Low Top Moon Boot" width="150" height="150"></b-img>
          <b-img src="@/assets/hightop.jpg" float alt="Low Top Moon Boot" width="150" height="150"></b-img></b-col>
     </b-row>
</b-container>
  </div>
  
 
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a:hover{text-decoration:none;}
.phone{font-size:.9em;}
.heading4{font-size:1.75em;}
</style>
