<template>
  <span>
   
   </span>
 
</template>

<script>
export default {


  name: 'Getdistance',
    props: 
        ['testProp','template'],
        
  
    data: function() {
        return {
         distance:''
        };
   },
   watch: { 
        testProp: function() {
          //alert("Test Prop" +this.testProp)
        //this.sayHello(this.testProp,this.prop2)
        this.getDistanceFunc(this.testProp);
        }
    },
  methods:{
       sayHello:function(a,b){
            alert("HK" +a+ "--" + b);
        },
         
          getDistanceFunc: function(address){
              
              //alert("In getDistanceFunc"+address);
             
              //var url="http://110.173.133.144:8081/autocomplete/getDistance";
            //  var url="https://medifoot.krishcom.com/autocomplete/getAddress";
            var url=process.env.VUE_APP_ROOT_API+"/autocomplete/getDistance";
            this.$http.get(url, {
                                     params: {
                                                queryString:address
                                     }
                                 })
            .then(
                (response) => {
                        this.seen=true
                          this.distance = response.data.predictions[0].distance_meters;
                         // alert("Distance:"+this.distance);
                          this.$emit('updateDistance', this.distance);
                          });
          }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
