<template>
  <div id="app">

    <div><Header msg="Moon Boots Delivered"/></div>
    <div><MoonBoots /></div>
        
  </div>
 
</template>

<script>
import MoonBoots from './components/MoonBoots.vue'
import Header from './components/Header.vue'
export default {
  
  name: 'App',
  components: {
  Header,MoonBoots
  }
 
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
