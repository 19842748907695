import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import Axios from 'axios'
import postscribe from 'postscribe'

Vue.prototype.$http = Axios;
Vue.prototype.$postscribe = postscribe;
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app')
